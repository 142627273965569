import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Lokální druhová rozmanitost (biodiverzita)" />
    <h1>Lokální druhová rozmanitost (biodiverzita)</h1>

    <p>Lok&aacute;ln&iacute; druhov&eacute; bohatstv&iacute; je jeden z typů&nbsp;<Link to="/biogeografie/biodiverzita/">biodiverzity</Link>&nbsp;vyjadřuj&iacute;c&iacute; počet unik&aacute;tn&iacute;ch druhů ve vymezen&eacute; lokalitě, a to ať už v region&aacute;ln&iacute;m či velmi podrobn&eacute;m měř&iacute;tku.</p>
    <p>Představa o druhov&eacute; bohatosti fl&oacute;ry v Evropě byla doned&aacute;vna založena pouze na n&aacute;rodn&iacute;ch seznamech druhů a atlasech roz&scaron;&iacute;řen&iacute; rostlin (např. <a href="https://www.luomus.fi/en/Linktlas-florae-europaeae-afe-distribution-vascular-plants-europe">Atlas Florae Europeae</a>). V současnosti se ale roz&scaron;iřuj&iacute; datab&aacute;ze sdružuj&iacute;c&iacute; fytocenologick&eacute; sn&iacute;mky př&iacute;stupn&eacute; z on-line prostřed&iacute;, např. <a href="http://euroveg.org/eva-database">European Vegetation Archive (EVA)</a>.</p>
    <hr />
    <h2>Faktory ovlivňuj&iacute;c&iacute; lok&aacute;ln&iacute; diverzitu rostlin</h2>
    <p>Mezi nejv&yacute;znamněj&scaron;&iacute; faktory ovlivňuj&iacute;c&iacute; lok&aacute;ln&iacute; druhovou bohatost rostlin patř&iacute;:</p>
    <ul>
    <li><strong>vhodn&eacute; podm&iacute;nky prostřed&iacute;</strong></li>
    <li><strong>vliv produktivity stanovi&scaron;tě</strong></li>
    <li><strong>vliv disturbanc&iacute;</strong></li>
    <li><strong>vliv okoln&iacute; krajiny</strong></li>
    <li><strong>vliv historie krajiny</strong></li>
    </ul>
    <h3>F1: Vhodn&eacute; podm&iacute;nky prostřed&iacute;</h3>
    <p>Podm&iacute;nky prostřed&iacute;, ať už m&iacute;ra kontinentality, &uacute;hrny sr&aacute;žek, teplota či jin&eacute;, ovlivňuj&iacute; signifikantně lok&aacute;ln&iacute; biodiverzitu. V&yacute;znamn&yacute;m faktorem je pak zejm&eacute;na pro lesn&iacute; vegetaci pH půdy.</p>
    <p>Ve středoevropsk&eacute;m prostřed&iacute; je mnohem v&iacute;ce druhů adaptovan&yacute;ch na z&aacute;sadit&eacute; půdy. V tomto prostřed&iacute; je tak vy&scaron;&scaron;&iacute; <strong>species pool </strong>(tj. soubor druhů, kter&eacute; jsou schopn&eacute; os&iacute;dlit lokalitu) bazifiln&iacute;ch druhů. Vy&scaron;&scaron;&iacute; pH tak obvykle znamen&aacute; vy&scaron;&scaron;&iacute; lok&aacute;ln&iacute; diverzitu.</p>
    <p><strong>Hypot&eacute;za nerovnoměrn&eacute;ho pleistocenn&iacute;ho vym&iacute;r&aacute;n&iacute;</strong> je historick&yacute;m vysvětlen&iacute;m častěj&scaron;&iacute; adaptace na vy&scaron;&scaron;&iacute; pH. Ta předpokl&aacute;d&aacute;, že převaha v&aacute;pnomiln&yacute;ch taxonů vypl&yacute;v&aacute; z vymřen&iacute; acidofiln&iacute;ch druhů při akumulaci v&aacute;pnit&yacute;ch spra&scaron;&iacute; v dob&aacute;ch ledov&yacute;ch.</p>
    <h3>F2: Vliv produktivity stanovi&scaron;tě</h3>
    <p>Produktivita stanovi&scaron;tě je vyj&aacute;dřen&iacute;m dostatků zdrojů a vhodn&yacute;ch podm&iacute;nek pro jejich využ&iacute;v&aacute;n&iacute; organismy.</p>
    <p>Při mal&eacute;m množstv&iacute; živin a produktivitě stanovi&scaron;tě je př&iacute;tomno mal&eacute; množstv&iacute; unik&aacute;tn&iacute;ch druhů. Diverzita postupně s produktivitou stanovi&scaron;tě roste, ale pokud je živin na stanovi&scaron;ti př&iacute;li&scaron; mnoho, začne druhov&aacute; diverzita opět klesat. Důvodem je siln&aacute; konkurence několika m&aacute;lo dobře adaptovan&yacute;ch druhů či např&iacute;klad vět&scaron;&iacute; velikost jedinců.</p>
    <h3>F3: Vliv disturbanc&iacute;</h3>
    <p>Disturbance, naru&scaron;ov&aacute;n&iacute; porostu a odstraňov&aacute;n&iacute; č&aacute;st&iacute; živ&eacute; biomasy, jsou důležit&yacute;m faktorem lok&aacute;ln&iacute; druhov&eacute; bohatosti.</p>
    <p>Zvět&scaron;ov&aacute;n&iacute; jej&iacute; intenzity a frekvence nejprve způsobuje n&aacute;růst biodiverzity, protože se omezuj&iacute; dominantn&iacute; druhy a vznik&aacute; prostor pro uplatněn&iacute; konkurenčně slab&scaron;&iacute;ch taxonů (při pastvě či pravideln&eacute; seči).</p>
    <p>Př&iacute;li&scaron; čast&aacute; či siln&aacute; disturbance v&scaron;ak znemožn&iacute; vět&scaron;ině druhů obnovovat ztracenou biomasu a druhov&aacute; bohatost tak opět kles&aacute;.</p>
    <h3>F4: Vliv okoln&iacute; krajiny</h3>
    <p>V&yacute;znamn&yacute;m faktor je jev zvan&yacute; <strong>spatial mass effect</strong>, což je pozitivn&iacute; vliv heterogenity okoln&iacute; krajiny.</p>
    <p>Pokud je v okoln&iacute; krajině v&iacute;ce stanovi&scaron;ť, mohou jejich druhy os&iacute;dlit sledovanou plochu, ačkoliv na n&iacute; nemusej&iacute; b&yacute;t ide&aacute;ln&iacute; podm&iacute;nky. Jejich př&iacute;tomnost je udržovan&aacute; neust&aacute;l&yacute;m př&iacute;sunem propagul&iacute; z okoln&iacute;ch stanovi&scaron;ť s př&iacute;zniv&yacute;mi podm&iacute;nkami.</p>
    <h4>F5: Vliv historie krajiny</h4>
    <p>Hotspoty současn&eacute; lok&aacute;ln&iacute; biodiverzity rostlinn&yacute;ch společenstev se často vyskytuj&iacute; v oblastech s dlouhou kontinuitou dan&eacute;ho společenstva. Př&iacute;kladem mohou b&yacute;t pozdně glaci&aacute;ln&iacute; refugia temper&aacute;tn&iacute;ch druhů, kter&eacute; jsou dnes bohat&yacute;mi společenstv&iacute;mi světl&yacute;ch lesů.</p>
    <p>Vliv historie krajiny m&aacute; obvykle silněj&scaron;&iacute; efekt na počet specialistů dan&eacute;ho vegetačn&iacute;ho typu.</p>
<hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Lokální druhová rozmanitost</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/biodiverzita/globalni-druhove-bohatstvi/"><button className="inv">&larr; Lokální druhová bohatost</button></Link>
    <Link to="/biogeografie/ostrovni/"><button className="inv">Ostrovní biogeografie &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
